import {
  Container,
  SimpleGrid,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Image,
  HStack
} from '@chakra-ui/react';
import {
  CheckIcon,
} from '@chakra-ui/icons';
import CustomerSupportImage from './customer_support.png'

const Feature = ({ text, icon, iconBg }) => {

  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function App() {

  return (
    <Container maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <HStack>
            <Text
              textTransform={'uppercase'}
              color={'orange.400'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('orange.50', 'orange.900')}
              p={3}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Patient Privacy
            </Text>
          </HStack>
          <Heading>Your Privacy is our Priority.</Heading>
          <Text color={'gray.800'} fontSize={'lg'} pt={25}>
            THIS NOTICE DESCRIBES HOW HEALTH INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. 
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
            <Feature
              icon={
                <Icon as={CheckIcon} color={'green.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Last updated August 4, 2022'}
            />
            {/* <Feature
              icon={<Icon as={CheckIcon} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Blappy'}
            />
            <Feature
              icon={
                <Icon as={CheckIcon} color={'purple.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Blloppy'}
            /> */}
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={CustomerSupportImage}
            objectFit={'cover'}
          />
        </Flex>
      </SimpleGrid>

      <Text color={'gray.500'} fontSize={'lg'} pb={15}>
      Your healthcare provider has engaged the services of IGH PLLC, doing business as Abortion On Demand or AOD (“Abortion On Demand”, “AOD”, “we”, “us”, or “our”), for management of text-based follow-up surveys. The purpose of these surveys is to ensure success of your medication abortion. We at AOD provide technical support required for collecting and securely relaying survey data from you to your healthcare provider.
      </Text>

      <Text color={'gray.500'} fontSize={'lg'} py={15}>
      We are required by applicable federal and state law to maintain the privacy of your health information. We are also required to give you this Notice about our privacy practices, our legal duties and your rights concerning your health information. We must follow the privacy practices that are described in this Notice while it is in effect. This Notice takes effect August 4, 2022 and will remain in effect until we replace it.
      </Text>

      <Text color={'gray.500'} fontSize={'lg'} fontWeight={800} py={5}>
      USES AND DISCLOSURES OF HEALTH INFORMATION
      </Text>

      We use and disclose health information about you for treatment and healthcare operations. For example: 

      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Treatment:
      </Text>
      <Text color={'gray.500'} fontSize={'lg'}>
      We may use or disclose your health information to a physician or other healthcare provider providing treatment to you. 
      </Text>

      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Healthcare Operations:
      </Text>
      We may use and disclose your health information in connection with our healthcare operations. Healthcare operations include quality assessment and improvement activities, reviewing the competence or qualifications of healthcare professionals, evaluating practitioner and provider performance, conducting training programs, accreditation, certification, licensing or credentialing activities.
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Your Authorization: 
      </Text>

      In addition to our use of your health information for treatment or healthcare operations, you may give us written authorization to use your health information or to disclose it to anyone for any purpose. If you give us an authorization, you may revoke it in writing at any time. Your revocation will not affect any use or disclosures permitted by your authorization while it is in effect. Unless you give us a written authorization, we cannot use or disclose your health information for any reason except those described in this Notice.
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      To Your Family and Friends:
      </Text>

      We must disclose your health information to you, as described in the Patient Rights section of this Notice. We do not disclose health information to family members unless authorized by you in writing.
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Persons Involved in Care:
      </Text>
      In the event of your incapacity or emergency circumstances, we will disclose health information based on a determination using our professional judgment disclosing only health information that is directly relevant to an individual’s involvement in your healthcare.
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Marketing Health-Related Services:
      </Text>
      We will not use your health information for marketing communications.
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Required by Law:
      </Text>
      We may use or disclose your health information when we are required to do so by law. This includes lawful subpoenas by government entities.
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Abuse or Neglect:
      </Text>
      We may disclose your health information to appropriate authorities if we reasonably believe that you are a possible victim of abuse, neglect, or domestic violence or the possible victim of other crimes. We may disclose your health information to the extent necessary to avert a serious threat to your health or safety or the health or safety of others.
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      National Security:
      </Text>
      We may disclose to military authorities the health information of Armed Forces personnel under certain circumstances. We may disclose to authorized federal officials health information required for lawful intelligence, counterintelligence, and other national security activities. We may disclose to correctional institutions or law enforcement officials having lawful custody of protected health information of inmates or patients under certain circumstances. 
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={800} py={5}>
      PATIENT RIGHTS: 
      </Text>

      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Access:
      </Text>
      You have the right to look at or get copies of your health information, with limited exceptions. You may request that we provide copies in a format other than digital copies. We will use the format you request unless we cannot practicably do so. You should contact your healthcare provider directly to obtain this health information. 
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Disclosure Accounting: 
      </Text>
      You have a right to receive a list of instances in which we disclosed your health information for purposes other than treatment, healthcare operations and certain other activities for the last 3 years. If you request this accounting more than once in a 12-month period, we may charge you a reasonable cost-based fee for responding to these additional requests. 
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Restriction:
      </Text>
      You have the right to request that we place additional restrictions on our use or disclosure of your health information. We are required to agree to these additional restrictions, but if we do, we will abide by our agreement (except in emergency).
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Amendment:
      </Text>
      You have the right to request that we amend your health information. Your request must be made through your healthcare provider. We may deny your request under certain circumstances. 
      
      <Text color={'gray.500'} fontSize={'lg'} fontWeight={600} py={5}>
      Questions and Complaints:
      </Text>
      <Text fontSize={'lg'}>
        If you want more information about our privacy practices or have questions or concerns, please contact us at info@abortionondemand.org. You may also contact your healthcare provider directly.
      </Text> 
      <Text fontSize={'lg'}>
      If you are concerned that we may have violated your privacy rights, or you disagree with a decision we made about access to your health information or in response to a request you made to amend or restrict the use or disclosure of your health information or to have us communicate with you by alternative means or at alternative locations, you may send a written complaint to our office or to the U.S. Department of Health and Human Services, Office of Civil Rights. We will not retaliate against you for filing a complaint. The mailing address for our office is 1037 NE 65th St Suite 371 Seattle, WA 98115.
      </Text>
    </Container>
  );
}
